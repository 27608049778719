import {
  Component,
  OnInit,
  OnDestroy,
  AfterViewInit,
  ViewChild,
  ElementRef,
  Renderer2,
  HostListener
} from "@angular/core";
import noUiSlider from "nouislider";

@Component({
  selector: "app-index",
  templateUrl: "index.component.html",
  styleUrls: ["index.component.css"]
})
export class IndexComponent{
  isCollapsed = true;
  focus;
  focus1;
  focus2;
  date = new Date();
  pagination = 3;
  pagination1 = 1
  isScrolledUp: boolean = false;

  @HostListener('window:scroll', ['$event'])
  onScroll(event: any) {
    if (window.pageYOffset > 100) {
      this.isScrolledUp = true;
    } else {
      this.isScrolledUp = false;
    }
  }

  constructor(private el: ElementRef) {
  }


  onNextClick() {
    let lists = document.querySelectorAll('.item-slider');
    document.getElementById('slide-paint').appendChild(lists[0]);
  }

  onPrevClick() {
    let lists = document.querySelectorAll('.item-slider');
    document.getElementById('slide-paint').prepend(lists[lists.length - 1]);
  }

  onAquaPrevClick() {
    let listsAqua = document.querySelectorAll('.item-aqua-slider');
    document.getElementById('slide-aqua-paint').appendChild(listsAqua[0]);
  }

  onAquaNextClick() {
    let listsAqua = document.querySelectorAll('.item-aqua-slider');
    document.getElementById('slide-aqua-paint').prepend(listsAqua[listsAqua.length - 1]);
  }

  scrollToTop() {
    window.scrollTo(0, 0);
  }

  onChimneyNextClick() {
    let listsChimney = document.querySelectorAll('.item-chimney-slider');
    document.getElementById('slide-chimney-paint').appendChild(listsChimney[0]);
  }

  onChimneyPrevClick() {
    let listsChimney = document.querySelectorAll('.item-chimney-slider');
    document.getElementById('slide-chimney-paint').prepend(listsChimney[listsChimney.length - 1]);
  }

  scrollToPeintureMurale() {
    const peintureMuraleId = this.el.nativeElement.querySelector('#peinture-murale');

    // Check if the element is found
    if (peintureMuraleId) {
      let scrollPosition: number;

      // Check the screen width to determine the offset
      if (window.innerWidth <= 768) { // Mobile screen
        scrollPosition = peintureMuraleId.offsetTop - 22;
      } else { // Desktop screen
        scrollPosition = peintureMuraleId.offsetTop - 55;
      }

      window.scrollTo({ top: scrollPosition, behavior: 'smooth' });
    }
  }

  scrollToAquarium() {
    const aquariumId = this.el.nativeElement.querySelector('#aquarium');

    // Check if the element is found
    if (aquariumId) {
      let scrollPosition: number;
      // Check the screen width to determine the offset
      if (window.innerWidth <= 768) { // Mobile screen
        scrollPosition = aquariumId.offsetTop -22;
      } else {
        scrollPosition = aquariumId.offsetTop - 55;
      }
      window.scrollTo({ top: scrollPosition, behavior: 'smooth' });
    }
  }

  scrollToChimney() {
    const chimneyId = this.el.nativeElement.querySelector('#chimney');

    // Check if the element is found
    if (chimneyId) {
      let scrollPosition: number;
      // Check the screen width to determine the offset
      if (window.innerWidth <= 768) { // Mobile screen
        scrollPosition = chimneyId.offsetTop - 22;
      } else {
        scrollPosition = chimneyId.offsetTop - 55;
      }
      window.scrollTo({ top: scrollPosition, behavior: 'smooth' });
    }
  }

  openMessenger() {
    // Replace the URL with your m.me link
    window.open("https://m.me/186458248073051", "_blank");
  }

  openWhatsApp() {
    // Replace the phone number with your WhatsApp phone number
    window.open("https://wa.me/20923740", "_blank");
  }
}
